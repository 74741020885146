import { Navigate } from 'react-router-dom';
import HookStatus from 'src/domains/root/commons/HookStatus';
import ContractTable from './ContractTable';
import useContractTableModel from './useContractTableModel';

export default function ContractTableContainer() {
  const {
    gateways,
    gatewaysError,
    gatewaysStatus,
    payments,
    paymentsResult,
    submitPayments,
    resetPaymentsResult,
    paymentsInvoicesReviewResult,
    updateGatewaysPaymentsInvoceReview,
  } = useContractTableModel();
  const loading =
    gatewaysStatus === 'loading' ||
    paymentsInvoicesReviewResult.status === 'loading';
  const noData = gateways.length === 0;

  if (loading || !!gatewaysError || noData) {
    return (
      <HookStatus
        target="gatewaySettingTable"
        loading={loading}
        error={!!gatewaysError}
        noData={noData}
      />
    );
  }

  if (paymentsInvoicesReviewResult.status === 'hasError') {
    return (
      <RedirectionForGetPaymentsInvoicesReviewError
        errorCode={paymentsInvoicesReviewResult.errorCode}
      />
    );
  }

  const paymentsInvoicesReview = paymentsInvoicesReviewResult.data;

  return (
    <ContractTable
      gateways={gateways}
      payments={payments}
      paymentsResult={paymentsResult}
      submitPayments={submitPayments}
      resetPaymentsResult={resetPaymentsResult}
      paymentsInvoicesReview={paymentsInvoicesReview}
      updateGatewaysPaymentsInvoceReview={updateGatewaysPaymentsInvoceReview}
    />
  );
}

function RedirectionForGetPaymentsInvoicesReviewError({
  errorCode,
}: {
  errorCode: string;
}) {
  switch (errorCode) {
    case 'not_found':
      return <Navigate to="/errors/workspace-not-found" />;
    case 'permission_denied':
      return <Navigate to="/errors/permission-denied" />;
    case 'emergency_maintenance':
      return <Navigate to="/errors/emergency-maintenance" />;
    case 'unknown_error':
    default:
      return <Navigate to="/errors/unknown-error" />;
  }
}
